import React from 'react';
import { Section, SectionAlternate } from 'components/organisms';
import { ContactHero, ContactDetails, Contact, Subscription } from 'components/windrate';
import { makeStyles } from '@material-ui/styles';
// import { useTheme, useMediaQuery, colors } from '@material-ui/core';

import pagedata from "content/pages/contact.yaml";
import subscription from "content/global/subscription.yaml";
import contact from "content/global/contact.yaml";

const useStyles = makeStyles(theme => ({
  topPlaceholder: {
    //paddingBottom: 0,
    paddingTop: 0,
  },
  sectionAlternate2: {
    background: 'transparent',
    backgroundImage: `linear-gradient(180deg, ${theme.palette.background.paper} 40%, ${theme.palette.primary.dark} 0%)`,
  },  
}));

//const ContactPage = ({ themeMode }) => {
const ContactPage = () => {
  const classes = useStyles();
  // const theme = useTheme();
  /*
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const scrollTo = id => {
    setTimeout(() => {
      const element = document.querySelector(`#${id}`);
      if (!element) {
        return;
      }

      window.scrollTo({ left: 0, top: element.offsetTop, behavior: 'smooth' });
    });
  };
  */  
  return (
  <div>
    <Section className={classes.topPlaceholder}>
    </Section>
    <ContactHero data={pagedata.hero} />
    <ContactDetails data={pagedata.contactDetails} />
    <SectionAlternate>
        <Contact data={contact} />
    </SectionAlternate>
    <SectionAlternate innerNarrowed className={classes.sectionAlternate2}>
      <Subscription data={subscription} />
    </SectionAlternate>
  </div>
  );
}

export default ContactPage;
